
import dayjs from '~/utils/dayjs'
export default {
  name: 'TemplatesPagesGroup',
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    group: {
      type: Object,
      required: true
    }
  },
  computed: {
    imgBase() {
      if (!this.group.image) {
        return null
      }

      return `${this.$config.CDN_URL}eventgroup/${this.id}/${this.group.image}`
    }
  },
  methods: {
    parseDate(date) {
      const _date = dayjs(date)
      return {
        month: '',
        day: _date.date(),
        year: _date.year()
      }
    },
    salesFrom(date) {
      return dayjs(date).format('DD.MM.YYYY HH:mm')
    },
    headingDate(date) {
      return dayjs(date).toDate()
    }
  }
}
