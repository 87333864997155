import { render, staticRenderFns } from "./description.vue?vue&type=template&id=5e51ed51&scoped=true&"
import script from "./description.vue?vue&type=script&lang=js&"
export * from "./description.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e51ed51",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartBar: require('/home/dayan/public_html/widgetdev.ticketshop.lv/components/Cart/Bar.vue').default})
